<template>
    <div class="homeOrder" v-if="afterSalesData.returnsId">
        <div class="title">我的售后-售后详情</div>
        <div class="homeSales">
            <div class="topHead">
                <div class="name">商品名称</div>
                <div class="specifications">商品规格</div>
                <div class="num">购买数量</div>
                <div class="realPay">实付价格</div>
            </div>
            <div class="comt">
                <div class="name">
                    <div class="img">
                        <img :src="afterSalesData.orderItem.img" alt="">
                    </div>
                    <div class="namee">{{ afterSalesData.orderItem.productName }}</div>
                </div>
                <div class="specifications">{{ afterSalesData.orderItem.valueDetailTxt }}</div>
                <div class="num">{{ afterSalesData.orderItem.pnum }}</div>
                <div class="realPay">¥{{ afterSalesData.orderItem.paymentMoney }}</div>
            </div>
        </div>
        <!-- 从协商历史里拿 -->
        <div class="salesType" v-if="negotiationHistory.length > 0">
            <div class="l">
                <div class="img">
                    <img :src="afterSalesData.orderItem.img" alt="">
                </div>
                <div class="text_time">
                    <div class="bt">{{ afterSalesData.statusDesc }}</div>
                    <div class="time">{{ negotiationHistory[negotiationHistory.length - 1].gmtCreate }}</div>
                </div>
            </div>
            <div class="r" @click="dialogVisible2 = true">
                <div class="text">协商记录</div>
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>

        <!-- 协商记录对话框 -->
        <el-dialog title="协商记录" :visible.sync="dialogVisible2" width="50%">
            <div style="max-height: 500px;overflow-y: auto;width: 100%;">
                <div class="son_dialog" v-for="(i, index) in negotiationHistory" :key="index">
                    <div class="t_img_name">
                        <div class="img">
                            <img :src="i.mallUserInfo ? i.mallUserInfo.userImage : i.mallstore.storeLog" alt="">
                        </div>
                        <div class="info">
                            <div class="name">{{ i.mallUserInfo ? i.mallUserInfo.userName : i.mallstore.storeName }}</div>
                            <div class="time">{{ i.gmtCreate }}</div>
                        </div>
                    </div>
                    <div class="conten">{{ i.description }}</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
            </span>
        </el-dialog>


        <div class="info" v-if="!modifyTheApplicationForShow">
            <div class="theRefundInformation">
                <div class="title">退款信息</div>
                <div class="son">
                    <div class="til">退款原因</div> {{ afterSalesData.refundReson }}
                </div>
                <div class="son">
                    <div class="til"> 申请时间</div> {{ afterSalesData.createTime }}
                </div>
                <div class="son">
                    <div class="til"> 订单编号</div> {{ afterSalesData.oid }}
                </div>
                <div class="son">
                    <div class="til">退款编号</div> {{ afterSalesData.refundTradeNo || '--' }}
                </div>
            </div>
            <div class="credentialsToDescribe">
                <div class="title">凭证描述</div>
                <div class="son">
                    <div class="til">退款原因</div>
                    <div class="img" v-if="afterSalesData.returnImage"
                        style="width: calc(100% - 100px);display: flex;gap: 20px;align-items: center;">
                        <img style="width: 50px;height: 35px; object-fit: cover;"
                            @click="dialogVisibleImg = true, dialogImageUrlImg = i"
                            v-for="(i, inde) in afterSalesData.returnImage.split(',')" :src="i" alt="">
                    </div>
                </div>
                <div class="son">
                    <div class="til"> 退款描述 </div> {{ afterSalesData.description }}
                </div>
            </div>
            <!-- 点击图片放大 -->
            <el-dialog :visible.sync="dialogVisibleImg">
                <img width="100%" :src="dialogImageUrlImg" alt="">
            </el-dialog>
        </div>

        <div class="btn_box" v-if="!modifyTheApplicationForShow">
            <el-dropdown @command="dele" v-if="afterSalesData.status === '1' || afterSalesData.status === '2'">
                <span class="el-dropdown-link">
                    <div class="btn">取消售后</div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="i.value" v-for="(i, ind) in cancellationReason" :key="ind">{{ i.value
                    }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <div class="btn2" @click="modifyTheApplicationForShow = true"
                v-if="afterSalesData.status === '1' || afterSalesData.status === '2'">修改申请</div>
            <!-- <div class="btn">联系商家</div> -->
        </div>

        <!-- 修改售后 -->
        <div class="modifyTheApplicationFor" v-if="modifyTheApplicationForShow">
            <div class="title">修改售后类型</div>
            <div class="type">
                <div :class="[i.act ? 'actShow' : '']" @click="typeClick(index)" v-for="(i, index) in typeAfterSalesArr"
                    :key="index">{{ i.name }}</div>
            </div>
            <div class="select">
                <div class="titl">选择退款原因</div>
                <el-select v-model="value" placeholder="请选择" size="mini">
                    <el-option v-for="(i, index) in yunayinList" :key="index" :label="i.label" :value="i.label">
                    </el-option>
                </el-select>
            </div>
            <div class="select">
                <div class="titl">退款金额</div>
                <div class="je">¥{{ afterSalesData.orderItem.paymentMoney }}</div>
            </div>
            <div class="select">
                <div class="titl">问题描述</div>
                <div class="textarea">
                    <el-input type="textarea" :rows="5" maxlength="200" show-word-limit placeholder="请输入内容"
                        v-model="textarea">
                    </el-input>
                </div>
            </div>
            <div class="select">
                <div class="titl">图片信息</div>
                <div class="upImg">
                    <el-upload :file-list="fileList" :limit="9" action="https://api.cnc-x.cn//mall/uploads"
                        list-type="picture-card" :data="{ type: '12' }" :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove" :on-change="handleChange" :on-exceed="onExceed">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                    <div class="texxt">
                        为了帮助您更好的解决问题，请上传图片
                    </div>
                </div>
            </div>
            <div class="select btn_b">
                <div class="tn2" @click="modifyTheApplicationForShow = false">取消修改</div>
                <div class="tn" @click="queRenModify">确认修改</div>
            </div>
        </div>

    </div>
</template>

<script>
import { getAfterSalesInfo, getAfterSalesNegotiationHistory, getCancellationAfterSalesReason, getCancellationAfterSales } from '@/utils/api/myApi/index'
export default {
    name: 'afterTheDetails',
    data() {
        return {
            dialogVisibleImg: false,
            dialogImageUrlImg: '',
            // 修改申请
            modifyTheApplicationForShow: false,
            typeAfterSalesArr: [
                {
                    name: '我要退款',
                    act: true,
                },
                {
                    name: '我要退货',
                    act: false,
                }
            ],
            // 退款原因
            yunayinList: [{
                value: '1',
                label: '商品的描述的尺寸与实物不符'
            },
            {
                value: '2',
                label: '做工问题'
            },
            {
                value: '3',
                label: '包装/商品破损/污渍'
            },
            {
                value: '4',
                label: '未按约定时间发货'
            },
            {
                value: '5',
                label: '卖家发错货'
            },
            {
                value: '其他',
                label: '其他'
            }
            ],
            value: '',
            textarea: '',
            dialogVisible2: false,
            dialogImageUrl: '',
            dialogVisible: false,
            typeIndex: '1',
            // 售后订单id
            returnsId: '',
            // 售后数据
            afterSalesData: {},
            // 协商历史记录
            negotiationHistory: [],
            // 取消原因
            cancellationReason: [],
            // 上传的图片
            evaluateImage: '',
            evaluateImageArr: [],
            fileList: []

        }
    },
    mounted() {
        this.returnsId = this.$route.query.returnsId
        this.afterSalesInfo()
        this.getNegotiationHistory()
        this.getCancellationReason()
    },
    methods: {
        // 获取取消售后原因
        async getCancellationReason() {
            const res = await getCancellationAfterSalesReason({
                value: '取消原因'
            })
            if (res.statusCode == 8201) {
                this.cancellationReason = res.data[0].dictionaryVoList
            }
        },
        // 获取协商记录
        async getNegotiationHistory() {
            const res = await getAfterSalesNegotiationHistory(this.returnsId)
            if (res.statusCode == 8201) {
                this.negotiationHistory = res.data
            }
        },
        // 获取售后详情
        async afterSalesInfo() {
            const res = await getAfterSalesInfo(this.returnsId)
            if (res.statusCode == 8201) {
                this.afterSalesData = res.data
                this.typeIndex = this.afterSalesData.status
                this.typeClick(this.typeIndex - 1)
                this.textarea = this.afterSalesData.description
                this.value = this.afterSalesData.refundReson
                this.evaluateImage = this.afterSalesData.returnImage
                this.evaluateImageArr = this.evaluateImage.split(',')
                this.fileList = this.evaluateImageArr.map(url => ({
                    name: url.split('/').pop(), // 根据实际情况获取文件名
                    url
                }))
            }
        },
        // 确认修改
        async queRenModify() {
            let data = {
                description: this.textarea,
                detailId: this.afterSalesData.detailId,
                oid: this.afterSalesData.oid,
                refundAmount: this.afterSalesData.orderItem.paymentMoney,
                refundReson: this.value,
                returnImage: this.evaluateImage,
                returnMold: 'SQZ',
                returnsId: this.afterSalesData.returnsId,
                returnsStatus: this.afterSalesData.orderInfo.otype == 'AGENT' ? 'agent' : 'common',
                status: this.typeIndex,
                storeId: this.afterSalesData.storeId
            }
            console.log(data)
            const res = await getCancellationAfterSales(data)
            if (res.data) {
                this.$message.success('修改成功')
                setTimeout(() => {
                    this.$router.push('/myIndex/myAfterSales?actMyNum=1')
                }, 2000)
            } else {
                this.$message.error('修改失败')
            }
        },
        typeClick(index) {
            this.typeAfterSalesArr.map(t => t.act = false)
            this.typeAfterSalesArr[index].act = true
            this.typeIndex = (index + 1) + ''
        },
        handleRemove(file, fileList) {
            console.log(file, fileList, '删除');
            this.handleChange(file, fileList)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleChange(file, fileList) {
            console.log(fileList.map(f => f.response?.data || f.url).join(','), '变化')
            this.evaluateImage = fileList.map(f => f.response?.data || f.url).join(',')
            console.log(fileList)
            // fileList.map
        },
        onExceed(files, fileList) {
            this.$message.error('最多上传9张')
        },
        // 取消售后
        dele(command) {
            let that = this
            this.$confirm('确认取消售后吗?', '提示', {
                confirmButtonText: '取消售后',
                cancelButtonText: '继续售后',
                type: 'warning'
            }).then(async () => {
                const res = await getCancellationAfterSales({
                    cancelCause: command,
                    status: '0',
                    returnsId: that.returnsId
                })
                if (res.data) {
                    this.afterSalesInfo()
                    this.getNegotiationHistory()
                    this.$message.success('已取消售后')
                } else {
                    this.$message.error('操作失败')
                }
            }).catch(() => {
                this.$message.info('继续售后')
            })
        },
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 100%;
    object-fit: cover !important;
}

.homeOrder {
    width: 1050px;
    height: auto;

    >.title {
        width: 100%;
        height: 40px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }

    >.homeSales {
        width: calc(100% - 18px);
        margin-left: 18px;
        height: 133px;
        border: 1px solid #CCCCCC;
        margin-top: 22px;

        >.topHead {
            width: 100%;
            height: 38px;
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 38px;
            color: #333;
            font-size: 14px;

            >.name {
                width: 440px;
                height: 100%;
            }

            >.specifications {
                width: 220px;
                height: 100%;
            }

            >.num {
                width: 160px;
                height: 100%;
            }

            >.realPay {
                width: 217px;
                height: 100%;
            }
        }

        >.comt {
            width: 100%;
            height: 95px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #666666;
            font-size: 14px;
            background-color: #fff;

            >.name {
                width: 440px;
                height: 100%;
                display: flex;
                padding: 15px;
                box-sizing: border-box;
                border-right: 1px solid rgba(151, 151, 151, 0.50);

                >.img {
                    width: 64px;
                    height: 64px;
                    margin-right: 18px;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                >.namee {
                    width: 210px;
                    text-align: left;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-height: 2.6rem;
                }
            }

            >.specifications {
                width: 220px;
                height: 100%;
                line-height: 95px;
                border-right: 1px solid rgba(151, 151, 151, 0.50);

            }

            >.num {
                width: 160px;
                line-height: 95px;
                height: 100%;
                border-right: 1px solid rgba(151, 151, 151, 0.50);

            }

            >.realPay {
                width: 217px;
                height: 100%;
                line-height: 95px;
            }
        }

    }

    >.salesType {
        width: calc(100% - 18px);
        margin-left: 18px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        padding: 8px 13px;
        box-sizing: border-box;
        margin-top: 20px;

        >.l {
            display: flex;
            align-items: center;

            >.img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 15px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            >.text_time {
                >.bt {
                    font-size: 14px;
                    font-weight: bold;
                    color: #333;
                }

                >.time {
                    font-size: 12px;
                    color: #999;
                    margin-top: 4px;
                }
            }
        }

        >.r {
            display: flex;
            align-items: center;
            cursor: pointer;

            >.text {
                font-size: 14px;
                font-weight: bold;
                color: #000;
                margin-right: 20px;
                transition: all .3s;
            }

            >i {
                color: #666;
                margin-top: 2px;
                transition: all .3s;
            }
        }

        >.r:hover {

            >.text,
            i {
                color: #FF4242 !important;
                transition: all .3s;
            }

        }
    }

    >.info {
        width: calc(100% - 18px);
        margin-left: 18px;
        height: auto;
        background-color: #fff;
        padding: 17px 22px;
        box-sizing: border-box;
        margin-top: 20px;

        >.theRefundInformation {
            width: 100%;
            height: auto;
            padding-bottom: 20px;
            box-sizing: border-box;
            border-bottom: 1px solid #E0E0E0;

            >.title {
                font-size: 16px;
                color: #333;
                font-weight: bold;
                margin-bottom: 15px;
            }

            >.son {
                font-size: 14px;
                color: #333;
                display: flex;
                align-items: center;
                margin-top: 3px;

                >.til {
                    font-size: 14px;
                    color: #666;
                    margin-right: 20px;
                }
            }
        }

        >.credentialsToDescribe {
            width: 100%;
            height: auto;
            padding-top: 20px;
            box-sizing: border-box;

            >.son {
                font-size: 14px;
                color: #333;
                display: flex;
                align-items: center;
                margin-top: 3px;

                >.til {
                    font-size: 14px;
                    color: #666;
                    margin-right: 20px;
                }

                >.img {
                    width: 40px;
                    height: 40px;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }


    }

    >.modifyTheApplicationFor {
        width: calc(100% - 18px);
        margin-left: 18px;
        height: auto;
        background-color: #fff;
        padding: 19px 17px;
        box-sizing: border-box;
        margin-top: 20px;

        >.title {
            width: 100%;
            font-size: 14px;
            color: #333333;
        }

        >.type {
            display: flex;
            margin-top: 22px;

            >div {
                width: 127px;
                cursor: pointer;
                height: 32px;
                border-radius: 1px;
                font-size: 14px;
                color: #333333;
                text-align: center;
                line-height: 32px;
                margin-right: 10px;
                border: 1px solid rgba(153, 153, 153, 0.5);
            }
        }

        >.select {
            display: flex;
            margin-top: 20px;

            >.titl {
                font-size: 14px;
                color: #333;
                margin-right: 20px;
            }

            >.je {
                font-size: 14px;
                color: #FF4242;
            }

            >.textarea {
                width: 900px;
            }

            .texxt {
                font-size: 14px;
                color: #999;
                margin-top: 5px;
            }

            >.tn {
                width: 90px;
                height: 30px;
                border-radius: 4px;
                box-sizing: border-box;
                font-size: 12px;
                text-align: center;
                line-height: 30px;
                margin-bottom: 5px;
                cursor: pointer;
                margin-left: 20px;
                background-color: #FF4242;
                color: #fff;
            }

            >.tn2 {
                width: 90px;
                height: 30px;
                border-radius: 4px;
                box-sizing: border-box;
                font-size: 12px;
                text-align: center;
                line-height: 30px;
                margin-bottom: 5px;
                cursor: pointer;
                margin-left: 20px;
                color: #FF4242;
                border: 1px solid #FF4242;
            }
        }

        >.btn_b {
            display: flex;
            justify-content: flex-end;
        }
    }

    >.btn_box {
        width: calc(100% - 18px);
        margin-left: 18px;
        display: flex;
        justify-content: flex-end;
        margin-top: 45px;

        >div {
            width: 90px;
            height: 30px;
            border-radius: 4px;
            box-sizing: border-box;
            font-size: 12px;
            text-align: center;
            line-height: 30px;
            margin-bottom: 5px;
            cursor: pointer;
            margin-left: 20px;
        }

        .btn {
            background-color: #FF4242;
            color: #fff;
        }

        >.btn2 {
            color: #FF4242;
            border: 1px solid #FF4242;
        }
    }
}

.son_dialog {
    width: 99%;
    height: auto;
    padding: 17px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    margin-bottom: 10px;

    >.t_img_name {
        width: 100%;
        display: flex;
        align-items: center;

        >.img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 15px;

            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        >.info {
            width: 100%;

            >.name {
                color: #333;
                font-size: 14px;
                font-weight: bold;
            }

            >.time {
                color: #999;
                font-size: 12px;
            }
        }
    }

    >.conten {
        width: 100%;
        color: #333;
        font-size: 14px;
        margin-top: 10px;
    }
}

.actShow {
    border: 1px solid #FF4242 !important;
}


/*里面的代码可以根据自己需求去进行更改*/
/* 设置滚动条的样式 */
::-webkit-scrollbar {
    width: 4px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #d9d9d9;
}
</style>